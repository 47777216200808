@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v24/XRXK3I6Li01BKofIMPyPbj8d7IEAGXNiLXA3ig.ttf) format('truetype');
}
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v24/XRXK3I6Li01BKofIMPyPbj8d7IEAGXNirXA3ig.ttf) format('truetype');
}
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v24/XRXK3I6Li01BKofIMPyPbj8d7IEAGXNic3c3ig.ttf) format('truetype');
}
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v24/XRXK3I6Li01BKofIMPyPbj8d7IEAGXNiLXc3ig.ttf) format('truetype');
}
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v24/XRXI3I6Li01BKofiOc5wtlZ2di8HDDshRTM.ttf) format('truetype');
}
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v24/XRXI3I6Li01BKofiOc5wtlZ2di8HDLshRTM.ttf) format('truetype');
}
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v24/XRXI3I6Li01BKofiOc5wtlZ2di8HDGUmRTM.ttf) format('truetype');
}
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v24/XRXI3I6Li01BKofiOc5wtlZ2di8HDDsmRTM.ttf) format('truetype');
}
.font {
  font-family: 'Nunito', sans-serif;
}
.font2 {
  font-family: 'Nunito', sans-serif;
}
.fontSmall {
  font-family: 'Nunito', sans-serif;
  font-size: 12px;
}
.fontNormal {
  font-family: 'Nunito', sans-serif;
  font-size: 16px;
  font-weight: normal;
}
#ks-layout-full-root,
#ks-layout-root {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  height: 100%;
}
#ks-layout-full-root main,
#ks-layout-root main {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  background-color: #E7F7F5;
}
#ks-layout-full-root header,
#ks-layout-root header {
  height: 100px;
  color: #FFFFFF;
  background-color: #0FB1A1;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  position: relative;
}
#ks-layout-full-root footer,
#ks-layout-root footer {
  height: 100px;
  color: #FFFFFF;
  background-color: #414141;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  position: relative;
}
#ks-layout-full-root footer .copyright,
#ks-layout-root footer .copyright {
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-family: 'Nunito', sans-serif;
  font-size: 12px;
}
#ks-layout-full-root::before {
  content: "";
  z-index: -1;
  width: 100%;
  height: 100%;
  background: #414141 url("forest.jpg") no-repeat 0 0 fixed;
  top: 0;
  right: 0;
  position: absolute;
  display: inline-block;
}
#ks-layout-root main {
  padding: 20px 50px;
}
    #ks-layout-root header .ks-logout {
        float: right;
        margin-top: 10px;
        margin-right: 10px;
        font-weight: bold;
        cursor: pointer;
    }

        #ks-layout-root header .ks-logout:hover {
            color: white;
        }