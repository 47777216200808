@font-face {
    font-family: 'Nunito';
    font-style: italic;
    font-weight: 200;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/nunito/v24/XRXK3I6Li01BKofIMPyPbj8d7IEAGXNiLXA3ig.ttf) format('truetype');
}

@font-face {
    font-family: 'Nunito';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/nunito/v24/XRXK3I6Li01BKofIMPyPbj8d7IEAGXNirXA3ig.ttf) format('truetype');
}

@font-face {
    font-family: 'Nunito';
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/nunito/v24/XRXK3I6Li01BKofIMPyPbj8d7IEAGXNic3c3ig.ttf) format('truetype');
}

@font-face {
    font-family: 'Nunito';
    font-style: italic;
    font-weight: 800;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/nunito/v24/XRXK3I6Li01BKofIMPyPbj8d7IEAGXNiLXc3ig.ttf) format('truetype');
}

@font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/nunito/v24/XRXI3I6Li01BKofiOc5wtlZ2di8HDDshRTM.ttf) format('truetype');
}

@font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/nunito/v24/XRXI3I6Li01BKofiOc5wtlZ2di8HDLshRTM.ttf) format('truetype');
}

@font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/nunito/v24/XRXI3I6Li01BKofiOc5wtlZ2di8HDGUmRTM.ttf) format('truetype');
}

@font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/nunito/v24/XRXI3I6Li01BKofiOc5wtlZ2di8HDDsmRTM.ttf) format('truetype');
}

.font {
    font-family: 'Nunito',sans-serif;
}

.font2 {
    font-family: 'Nunito',sans-serif;
}

.fontSmall {
    font-family: 'Nunito',sans-serif;
    font-size: 12px;
}

.fontNormal {
    font-family: 'Nunito',sans-serif;
    font-size: 16px;
    font-weight: normal;
}

html, body, #root {
    height: 100%;
}

body {
    background-color: #fff;
    font-family: 'Nunito',sans-serif;
    font-size: 16px;
    font-weight: normal;
}

h1 {
    font-family: 'Nunito',sans-serif;
    font-size: 28px;
    font-weight: 800;
}

.a {
    color: #414141;
    text-decoration: underline;
}

a, a:visited, a:focus, a:link {
    color: #414141;
    text-decoration: underline;
}

.ks-link {
    cursor: pointer;
    color: #414141;
    text-decoration: underline;
}

.ks-form-container .ks-form-fieldgroup {
    margin-bottom: 10px;
}

.ks-form-error {
    background-color: #f3f0f6;
    display: block;
    padding: 10px;
}

label {
    width: 33%;
    text-align: right;
    padding-right: 10px;
}

input {
    width: 66%;
    border: 1px solid #414141;
    font-family: 'Nunito',sans-serif;
    font-size: 16px;
    font-weight: normal;
}

    input:focus {
        outline: none;
        background-color: #c3ece8;
    }

    button, input[type=submit] {
        background-color: transparent;
        border: 1px solid #414141;
    }

        button:focus, input[type=submit]:focus {
            outline: none;
            background-color: #4cc5b9;
        }

.ks-table {
    display: table;
    width: 100%;
    border-bottom: 1px solid #414141;
}

    .ks-table > div {
        display: table-row;
    }

        .ks-table > div > div {
            display: table-cell;
            border-top: 1px solid #414141;
            padding: 10px;
        }

#ks-form-login {
    z-index: 100;
    width: 400px;
    margin: 0 auto;
    position: relative;
}

    #ks-form-login #ks-form-login-inner {
        padding: 50px;
        margin-top: 100px;
        border: 2px solid #0fb1a1;
    }

        #ks-form-login #ks-form-login-inner::after {
            content: "";
            width: 100%;
            height: 100%;
            background: #e7f7f5;
            top: 0;
            right: 0;
            position: absolute;
            z-index: -2;
            opacity: .8;
        }

.ks-padding-ten {
    padding: 10px;
}
